import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router'

@Component({
  selector: 'app-core-competence',
  templateUrl: './core-competence.component.html',
  styleUrls: ['./core-competence.component.less']
})
export class CoreCompetenceComponent implements OnInit {

  constructor(
    private titleService: Title,
    private myActivatedRoute: ActivatedRoute

  ) { }

  // 动态设置值
  dynamicSetTitle(): void {
    this.titleService.setTitle('核心能力-智纹科技');
  }

  public title = '核心能力';

  public coreList: Array<any> = [
    {
      anchorId: 'core1',
      imgUrl: '/assets/img/stexture-core-content1.png',
      title: '计算机视觉',
      desc: '现代科学技术的发展一日千里，许多企业的生产方式都在朝着从劳动密集化，向集约化、智能化的方向发展。但由于传统「工业视觉」的技术限制，在当前生产流程中，依旧存在着大量需要「人眼」决策和判断的环节。这里面存在三个问题：一、制约规模化生产；二、影响产品品质的稳定性；三、存在人员流失即能力流失的风险。',
      desc1: 'Stexture©智纹科技的「计算机视觉能力」是对传统「工业视觉」的一次重大升级，可以在很大程度上解决或缓解上述问题。与传统「工业视觉」仅针对简单的视觉特征进行提取和判断不同，Stexture©智纹科技基于前沿的人工智能和深度学习技术，结合突破性自研算法，让工业机器也拥有了像人眼一样的对视觉信息的理解与判断能力。将此能力应用到工业流程中，可以帮助企业突破技术瓶颈，实现工业智能化的新升级。'
    },
    {
      anchorId: 'core2',
      imgUrl: '/assets/img/stexture-core-content2.png',
      title: '跨学科融合',
      desc: '对于工业智能化的长期目标来说，需要跨学科融合才能拓展其应用范围。这里面的挑战有两个方面：第一，是视觉信息需要其它学科知识的辅助，例如黄金表面的细微瑕疵，需要「光学」维度的辅助才能清晰得到；第二，是如何将除视觉外的其他纬度应用到工业智能化当中，例如物理质量、阴极射线、磁力、超声波反馈等。',
      desc1: 'Stexture©智纹科技的「跨学科融合」能力拓展了工业智能化的能力范围。在专注计算机视觉研究的基础上，Stexture©智纹科技更聚集了物理、光学、化学、电子等领域的顶尖科学家，突破性地将跨学科的技术能力融合起来，为客户打造能力广泛的综合性「工业大脑」。在我们诸多的工业智能化落地案例中，我们已经在多个工业场景中成功打破并重新定义了当前的技术天花板，助力客户在数字时代降本提效，节流开源。'
    },
    {
      anchorId: 'core3',
      imgUrl: '/assets/img/stexture-core-content3.png',
      title: '高精密场景化传送',
      desc: '在大多数的工业化生产过程中，产品（物体）需要被不断地「精确搬运」到各个位置以完成各种工序。当前许多工业智能化所面临的难题，场景化的高精密传送是一个很大的掣肘。虽然市面上已经有了不少工业机械臂和机器人产品及方案，但其与各种生产场景的匹配，比如，微小零部件的安装与质检，在很多情况下仍未能达到预期效果。',
      desc1: 'Stexture©智纹科技的「高精密场景化传送」顾名思义，「高精密」和「场景化」是这个能力的两大核心。基于自身专业团队与精密机械的设计及实现能力，结合行业资源，我们所推出的机器人产品和方案，均能满足各行业对于精度的严苛要求。此外，针对客户的具体需求，我们也可以最大限度地做到「因地制宜」，全程调研及分析客户的真实生产流程，满足客户细粒度的「客制化」需求，助力智能制造的有效落地。'
    },
    {
      anchorId: 'core4',
      imgUrl: '/assets/img/stexture-core-content4.png',
      title: '云计算与大数据',
      desc: '后疫情时代如何利用云计算、大数据、人工智能等技术，进行智能化、数字化重塑，推动企业构建具有前瞻性的业务模式，提高企业竞争力，是企业需要持续探索的新命题。',
      desc1: 'Stexture©智纹科技的继承并延续了其母公司超过10年的云计算与大数据行业沉淀，基于我们在各行业「云计算与大数据」的优势系统能力，Stexture©智纹科技在珠宝、高端制造、能源、医疗、教育、零售、房地产等十余个行业均拥有了成熟的软件解决方案，服务全球数万家企事业单位。'
    },
  ]

  public goLocalAnchor(id: string) {
    let anchor = document.getElementById(id);
    anchor.scrollIntoView()
  }

  ngAfterViewInit(): void {
    this.myActivatedRoute.queryParams.subscribe(params => {
      if (params.id) {
        let anchor = document.getElementById(params.id)
        anchor.scrollIntoView()
      }
    })

  }

  ngOnInit(): void {
    this.dynamicSetTitle();
  }


}
