import { Component, OnInit, HostListener } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

// declare 引入外部变量时声明使用
declare var BMap: any;
declare var BMAP_ANIMATION_BOUNCE: any;
declare var BMAP_NORMAL_MAP: any;
declare var BMAP_HYBRID_MAP: any;

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.less']
})
export class AboutUsComponent implements OnInit {

  constructor(
    private meta: Meta,
    private titleService: Title,
  ) { }

  // 动态设置值
  dynamicSetTitle(): void {
    this.titleService.setTitle('关于我们-智纹科技');
    // this.meta.addTag({ name: 'keywords', content: '关于我们,百货基因' });
    // this.meta.addTag({ name: 'description', content: '百货基因技术由隶属于深圳企业云科技股份有限公司的燕麦云实验室所研发，与传统依赖生产工艺的防伪技术不同，百货基因实现了全球领先的数学防伪技术突破，基于像素间的数学关系嵌入防伪编码。服务热线：4000861108。' });
  }

  public title = '关于我们';
  // public desc = '百货基因是由「燕麦云实验室」所自主研发的突破性「数学」防伪技术。';

  public aboutUsList: Array<any> = [
    {
      imgUrl: '/assets/img/about-1.svg',
      title: '深圳智纹科技有限公司',
      desc: 'Stexture©智纹科技（深圳智纹科技有限公司）是明寻科技（深圳明寻科技有限公司）的全资子公司，专注于以AI赋能珠宝生产与检测。基于明寻科技旗下核心科研机构「明寻实验室」所成功孵化出来的前沿人工智能与精密机器人技术，Stexture©智纹科技完美解决了当前珠宝行业中所面临的诸多挑战。',
      width: '70%',
      desc1: 'Stexture©智纹科技在贵金属珠宝检测以及钻石领域，都拥有领先的软硬件产品及方案。我们突破性地将计算机视觉、电子、物理光学、高精密传动等跨学科技术融合起来，实现了珠宝的「无损」成色检验与追踪溯源。Stexture©智纹科技成功打破并重新定义了行业所能达到的技术天花板，助力珠宝行业在数字时代降本提效，节流开源。'
    },
    {
      // imgUrl: '/assets/img/mingxun.svg',
      title: '明寻实验室',
      desc: '「明寻实验室」专注计算机视觉、人工智能、区块链、机器人、物联网等方向的前沿技术探索与产业转化。明寻实验室致力于以科技为本助力客户产业升级，创造技术价值，实现科技普惠。',
      subContent: [
        {
          imgUrl: '/assets/img/aboutUs-P2V1.png',
          desc: '计算机视觉'
        },
        {
          imgUrl: '/assets/img/aboutUs-P2V2.png',
          desc: '人工智能'
        },
        {
          imgUrl: '/assets/img/aboutUs-P2V3.png',
          desc: '区块链'
        },
        {
          imgUrl: '/assets/img/aboutUs-P2V4-2.png',
          desc: '机器人'
        },
        {
          imgUrl: '/assets/img/aboutUs-P2V5-2.png',
          desc: '物联网'
        },
      ]
    },
  ]

  public mapList: Array<any> = [
    {
      imgUrl: '/assets/img/about-3.png',
      title: '联系我们',
      title1: '深圳智纹科技有限公司',
      desc: '国际专线：+86-755-23358866<br><br>客服邮箱：hi@stexture.com<br><br>地址： 深圳市南山区粤海街道科技园社区科苑路15号科兴科学园A栋A1-1201',
    }
  ]

  ngOnInit(): void {
    this.dynamicSetTitle();
  }

  ngAfterContentInit() {
    setTimeout(() => {
      this.createMap()
    })
  }

  public isShowDefaultMap: boolean;

  createMap() {
    // 百度地图API功能
    var map = new BMap.Map("mdna-about-us-map");    // 创建Map实例
    var point = new BMap.Point(113.948819,22.554205);
    if (point) {
      this.isShowDefaultMap = false;
    }
    map.centerAndZoom(point, 19);  // 初始化地图,设置中心点坐标和地图级别
    var marker = new BMap.Marker(point);  // 创建标注
    map.addOverlay(marker);               // 将标注添加到地图中
    marker.setAnimation(BMAP_ANIMATION_BOUNCE); //跳动的动画

    //添加地图类型控件
    map.addControl(new BMap.MapTypeControl(
      {
        mapTypes: [
          BMAP_NORMAL_MAP,
          BMAP_HYBRID_MAP
        ]
      })
    );
    map.enableScrollWheelZoom(true);     //开启鼠标滚轮缩放
  };
}
