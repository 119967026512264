import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router'

@Component({
  selector: 'app-tech-innovation',
  templateUrl: './tech-innovation.component.html',
  styleUrls: ['./tech-innovation.component.less']
})
export class TechInnovationComponent implements OnInit {

  constructor(
    private meta: Meta,
    private titleService: Title,
    private myActivatedRoute: ActivatedRoute
  ) { }

  // 动态设置值
  dynamicSetTitle(): void {
    this.titleService.setTitle('黄金行业产品及方案-智纹科技');
    // this.meta.addTag({ name: 'keywords', content: '防伪技术,百货基因' });
    // this.meta.addTag({ name: 'description', content: '百货基因防伪技术是结合心理物理学与数学形态学的新一代数学防伪技术，实现了全球领先的计算机视觉底层算法突破，是基于像素间的数学关系嵌入编码的数学防伪技术。服务热线：4000861108。' });
  }


  public contentList: Array<any> = [
    {
      anchorId: 'stextureGold2',
      imgUrl: '/assets/img/stexture-home-content4.webp',
      title: '金珠自动化智能检测',
      desc: '基于精密传动产线及计算机视觉算法，实现金珠瑕疵的智能检测，自动化筛选剔除瑕疵件',
      descContent: [
        {
          title: '软硬一体',
          icon: '/assets/img/stexture-product-desc4.svg',
          content: '软硬一体化的精密流水线作业方案'
        },
        {
          title: '精确识别',
          icon: '/assets/img/stexture-product-desc5.svg',
          content: '经海量训练数据，精确识别金珠瑕疵'
        },
        {
          title: '降本增效',
          icon: '/assets/img/stexture-product-desc6.svg',
          content: '全自动识别金珠瑕疵品，减少人力成本，提升生产效率'
        }
      ]
    },
    {
      anchorId: 'stextureGold1',
      imgUrl: '/assets/img/stexture-home-content3.webp',
      title: '黄金内部品质无损检测',
      desc: '黄金检测领域的黑科技，应用强大的成像技术和AI人工智能，无损检测黄金内部成色及杂质情况',
      descContent: [
        {
          title: '内部检测参假',
          icon: '/assets/img/stexture-product-desc1.svg',
          content: '打破行业现有技术壁垒，轻松检测在黄金内部是否有钨、铱、钌等金属元素参假'
        },
        {
          title: '真正无损检测',
          icon: '/assets/img/stexture-product-desc2.svg',
          content: '检测全程对黄金不做任何破坏性操作，不接触液体，单纯依靠高精度成像及AI算法'
        },
        {
          title: '显示参假位置',
          icon: '/assets/img/stexture-product-desc3.svg',
          content: '检测出存疑的参假黄金时，系统将以图形化方式显示具体的黄金参假位置'
        }
      ]
    },
    {
      anchorId: 'stextureGold3',
      imgUrl: '/assets/img/stexture-home-content5.webp',
      title: '黄金款式识别',
      desc: '帮助黄金商家建立自己的黄金款式库，挖掘数据价值，降低库存的同时，实现营收的快速增长',
      descContent: [
        {
          title: '自动录入',
          icon: '/assets/img/stexture-product-desc7.svg',
          content: '可设计完整的流水化作业方案，软硬一体，黄金款式自动识别并录入'
        },
        {
          title: '精准分类',
          icon: '/assets/img/stexture-product-desc8.svg',
          content: '基于深度学习算法，智纹黄金款式识别可对黄金款式进行精准分类'
        },
        {
          title: '节流开源',
          icon: '/assets/img/stexture-product-desc9.svg',
          content: '积累数据，发掘数据，帮助商家降低库存，实现营收快速增长'
        }
      ]
    },
  ]

  public goLocalAnchor(id: string) {
    let anchor = document.getElementById(id);
    anchor.scrollIntoView()
  }

  ngOnInit(): void {
    this.dynamicSetTitle();
  }

  ngAfterViewInit(): void {
    this.myActivatedRoute.queryParams.subscribe(params => {
      if (params.id) {
        let anchor = document.getElementById(params.id);
          anchor.scrollIntoView()
      }
    })

  }

}
