import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router, NavigationExtras } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.less']
})
export class HomeComponent implements OnInit {

  constructor(
    private meta: Meta,
    private titleService: Title,
    private _router: Router

  ) { }

  // 动态设置值
  dynamicSetTitle(): void {
    this.titleService.setTitle('智纹科技-AI赋能珠宝生产与检测');
  }

  public headerTitle: Array<any> = [
    {
      title: '产品简介',
      router: '/home'
    },
    {
      title: '技术创新',
      router: '/tech-innovation'
    },
    {
      title: '场景优势',
      router: '/anti-fake'
    },
    {
      title: '关于我们',
      router: '/about-us'
    },
  ]


  public contentDetail1: any = [
    [
      {
        imgUrl: "/assets/img/stexture-core-content1.png",
        title: '计算机视觉',
        descDetail: '为机器赋予人眼似的视觉信息理解与判断能力',
        router: '/core',
        anchorId: 'core1',
      },
      {
        imgUrl: "/assets/img/stexture-core-content2.png",
        title: '跨学科融合',
        descDetail: '物理、光学、化学及电子等学科融合的工业大脑',
        router: '/core',
        anchorId: 'core2',
      },
    ],
    [
      {
        imgUrl: "/assets/img/stexture-core-content3.png",
        title: '高精密场景化传送',
        descDetail: '因地制宜的客制化，自动化精确传送',
        router: '/core',
        anchorId: 'core3',
      },
      {
        imgUrl: "/assets/img/stexture-core-content4.png",
        title: '云计算与大数据',
        descDetail: '超10年的云计算与大数据行业沉淀',
        router: '/core',
        anchorId: 'core4',
      },
    ],
  ]

  public contentDetail: any = [
    [
      {
        imgUrl: "/assets/img/stexture-home-content3.webp",
        title: '黄金首饰解决方案',
        descDetail: '黄金首饰自动筛选、检测、识别',
        router: '/product',
        anchorId: 'stextureGold',
      },
      {
        imgUrl: "/assets/img/stexture-home-content1.webp",
        title: '钻石行业解决方案',
        descDetail: '钻石数字身份证与腰码全自动识别',
        router: '/diamond',
        anchorId: 'stextureGold1',
      },
      {
        imgUrl: "/assets/img/stexture-home-content6.png",
        title: '其他行业解决方案',
        descDetail: '工业装备智能化、自动化及视觉检测',
        router: '/contact',
        anchorId: 'stextureGold3',
      },
    ],
    // [
    //   {
    //     imgUrl: "/assets/img/stexture-home-content1.webp",
    //     title: '钻石智纹码',
    //     descDetail: '钻石数字身份证',
    //     router: '/diamond',
    //     anchorId: 'stextureDiamond1',
    //   },
    //   {
    //     imgUrl: "/assets/img/stexture-home-content2.webp",
    //     title: '钻石腰码全自动识别',
    //     descDetail: '自动识别钻石腰码',
    //     router: '/diamond',
    //     anchorId: 'stextureDiamond2',
    //   }
    // ]
  ]

    // 跳转到锚点
    public goAnchor(router: string, id: string, event: any) {
      event.stopPropagation()
      event.preventDefault()
  
      const routerParams: NavigationExtras = {
        queryParams: {
          id,
        }
      };
      this._router.navigate([router], routerParams)
    }

  
  ngOnInit(): void {
    this.dynamicSetTitle();
  }

}
