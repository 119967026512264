import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from 'src/app/home/home.component';
import { AntiFakeComponent } from 'src/app/anti-fake/anti-fake.component';
import { AboutUsComponent } from 'src/app/about-us/about-us.component';
import { TechInnovationComponent } from 'src/app/tech-innovation/tech-innovation.component';
import { DiamondSolutionsComponent } from 'src/app/diamond-solutions/diamond-solutions.component';
import { ContactUsComponent } from 'src/app/contact-us/contact-us.component';
import { CoreCompetenceComponent } from 'src/app/core-competence/core-competence.component';
import { ConsultingDevelopmentComponent } from 'src/app/consulting-development/consulting-development.component';
const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
  },
  {
    path: 'product',
    component: TechInnovationComponent,
  },
  {
    path: 'support',
    component: AntiFakeComponent,
  },
  {
    path: 'diamond',
    component: DiamondSolutionsComponent,
  },
  {
    path: 'about',
    component: AboutUsComponent,
  },
  {
    path: 'contact',
    component: ContactUsComponent,
  },
  {
    path: 'home',
    component: HomeComponent,
  },
  {
    path: 'core',
    component: CoreCompetenceComponent,
  },
  {
    path: 'consultation',
    component: ConsultingDevelopmentComponent,
  },
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: '**',
    component: HomeComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, initialNavigation: 'enabled', anchorScrolling: 'enabled', scrollPositionRestoration: 'enabled', scrollOffset: [0, 64], })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
