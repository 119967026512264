<div class="mdna-header" id="mdna-header">
  <div class="stexture-header-div">
    <img src="./assets/img/stexture-logo-black.svg" height="28px" routerLink="/home"/>
  <ul class="stexture-header-ul">
    <li *ngFor="let title of headerTitle; let i = index">
      <a [routerLink]="title.router" class="stexture-header-atag">
        {{ title.title }}
        <ul *ngIf="i === 1"  [ngStyle]="{'display': 'none'}" class="stexture-header-subul">
          <li *ngFor="let item of tooltipContent">
            <ng-container *ngIf="item.icon">
              <img [src]="item.icon" width="25px" />
            </ng-container>

            <ng-container *ngIf="item.anchorId">
              <a
                (click)="goAnchor(item.routerUrl, item.anchorId, $event)"
                [ngStyle]="{
                  'margin-left': item.icon ? '10px' : '35px',
                  opacity: item.icon
                    ? '0.6000000238418579'
                    : '0.800000011920929'
                }"
              >
                {{ item.title }}
              </a>
            </ng-container>

            <ng-container *ngIf="!item.anchorId">
              <a
                (click)="goRouterLink(item.routerUrl, $event)"
                [ngStyle]="{
                  'margin-left': item.icon ? '10px' : '35px',
                  opacity: item.icon
                    ? '0.6000000238418579'
                    : '0.800000011920929'
                }"
              >
                {{ item.title }}
              </a>
            </ng-container>
          </li>
        </ul>
      </a>
    </li>
  </ul>

  <div class="stecture-contact-link"><a routerLink="/contact">联系我们</a></div>
  </div>
</div>
