<div class="mdna-about-us-header">
  <app-header></app-header>

  <div [ngStyle]="{ 'padding-top': '80px' }">
    <div class="stexture-home-img">
      <div class="stexture-home-img-content">
        <div class="stexture-home-img-content1">关于智纹</div>
        <div class="stexture-home-img-content2">About Stexture</div>
      </div>
    </div>
  </div>
</div>

<div class="mdna-about-us-wrapper" *ngFor="let item of aboutUsList">
  <div class="mdna-about-us-desc">
    <div class="mdna-about-us-desc-img" *ngIf="item.imgUrl">
      <img [src]="item.imgUrl"  [ngStyle]="{'width': item.width ? item.width : '100%'}"/>
    </div>
    <div class="mdna-about-us-desc-content">
      <div class="mdna-list-title">
        {{ item.title }}
      </div>
      <div *ngIf="!!item.title1">
        {{ item.title1 }}
      </div>
      <p [innerHTML]="item.desc"></p>
      <ng-container *ngIf="item.desc1">
        <p [ngStyle]="{'margin-top': '45px'}" [innerHTML]="item.desc1"></p>
      </ng-container>
      
      <ul *ngIf="!!item.subContent">
        <li *ngFor="let subContent of item.subContent">
          <img [src]="subContent.imgUrl" />
          <p>{{ subContent.desc }}</p>
        </li>
      </ul>
    </div>
  </div>
</div>


<!-- <div *ngFor="let item of laboratory">
<div>{{item.title}}</div>
<div>{{item.desc}}</div>
<ul *ngIf="!!item.subContent">
  <li *ngFor="let subContent of item.subContent">
    <img [src]="subContent.imgUrl" />
    <p>{{ subContent.desc }}</p>
  </li>
</ul>
</div> -->

<div class="mdna-about-us-wrapper" *ngFor="let item of mapList" id="map">
  <div class="mdna-about-us-desc">
    <div class="mdna-about-us-desc-img">
      <div
        class="mdna-about-us-map"
        id="mdna-about-us-map"
        [ngStyle]="{
          backgroundImage: isShowDefaultMap
            ? 'url(/assets/img/about-3.png)'
            : 'none'
        }"
      ></div>
    </div>
    <div class="mdna-about-us-desc-content">
      <div class="mdna-list-title">
        {{ item.title }}
      </div>
      <div *ngIf="!!item.title1">
        {{ item.title1 }}
      </div>
      <p [innerHTML]="item.desc"></p>
    </div>
  </div>
</div>

<app-footer></app-footer>
