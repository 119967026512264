<div class="mdna-home-header">
  <app-header></app-header>
  <div class="stexture-home-paddingtop80"></div>
  <div class="stexture-home-img">
    <div class="stexture-home-img-content">
      <div class="stexture-home-img-content1">工业智能化创新与践行者</div>
      <div class="stexture-home-img-content2">
        INDUSTRIAL INTELLIGENT INNOVATION AND PRACTITIONERS
      </div>
    </div>
  </div>
</div>

<div class="stexture-home-content">
  <div class="stexture-home-content-main">
    <div class="stexture-home-content-text">核心能力</div>
    <div
      class="stexture-home-content-img-first"
      *ngFor="let item of contentDetail1"
    >
      <div class="stexture-home-content-div" *ngFor="let subItem of item">
        <img [src]="subItem.imgUrl" class="stexture-home-content-img"/>
        <div class="stexture-home-content-mask">
          <div class="stexture-home-content-mask-title" [ngClass]="{'stexture-mask-title-margin': true}">
            {{ subItem.title }}
          </div>
          <div class="stexture-home-content-mask-desc">
            {{ subItem.descDetail }}
          </div>
          <div class="stexture-home-content-mask-tag">
            <a (click)="goAnchor(subItem.router, subItem.anchorId, $event)"
              >了解更多</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="stexture-home-content-main">
    <div class="stexture-home-content-text">产品方案</div>
    <div
      class="stexture-home-content-img-first"
      *ngFor="let item of contentDetail"
    >
      <div class="stexture-home-content-div" *ngFor="let subItem of item">
        <img [src]="subItem.imgUrl" class="stexture-home-content-img"/>
        <div class="stexture-home-content-mask">
          <div class="stexture-home-content-mask-title">
            {{ subItem.title }}
          </div>
          <div class="stexture-home-content-mask-desc">
            {{ subItem.descDetail }}
          </div>
          <div class="stexture-home-content-mask-tag">
            <a (click)="goAnchor(subItem.router, subItem.anchorId, $event)"
              >了解更多</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="stexture-footer-img">
    <div class="stexture-home-img-content">
      <div class="stexture-home-img-content1">在您身边，为您服务</div>
      <div class="stexture-home-img-content2">
        我们的售前工程师、技术专家及培训专家始终在您身边，为您提供快速响应的咨询服务、产品培训，全面保障您的业务运转。
      </div>
      <a routerLink="/support" class="stexture-home-img-atag">了解更多</a>
    </div>
  </div>
  <app-footer></app-footer>
</div>
