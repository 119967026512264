<div class="create-tooltip"></div>
<div class="tooltip-content">
  <ul>
    <li *ngFor="let item of data.content">
      <ng-container *ngIf="item.icon">
        <img [src]="item.icon" width="25px" />
      </ng-container>
      <a
        [ngStyle]="{
          'margin-left': item.icon ? '10px' : '35px',
          opacity: item.icon ? '0.6000000238418579' : '0.800000011920929'
        }"
      >
        {{ item.title }}
      </a>
    </li>
  </ul>
</div>
