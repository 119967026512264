<div class="stexture-contact-us-header">
  <app-header></app-header>
  <div [ngStyle]="{ 'padding-top': '80px' }">
    <div class="stexture-home-img">
      <div class="stexture-home-img-content">
        <div class="stexture-home-img-content1">咨询与开发</div>
        <div class="stexture-home-img-content2">Consulting and development</div>
      </div>
    </div>
  </div>
</div>

<div class="stexture-contact-us-title">服务领域</div>

<div class="stexture-contact-us-service">
  <ul>
    <li *ngFor="let item of contactContent">
      <img [src]="item.imgUrl" width="100%" />
      <div>{{ item.text }}</div>
      <span>{{ item.detail }}</span>
    </li>
  </ul>
</div>

<div class="stexture-contact-us-content">
  <div class="stexture-contact-us-content-left">
    <p>联系定制</p>
    <div>我们以认真严谨的态度承接您的咨询和定制的开发任务。</div>
    <div>
      我们的高素质行业专家团队，会与您充分沟通，对您的任务进行细致设计，透明、高效地完成任务。开发结果将体现在详细的文件中，还会提供功能齐备的原型件。
    </div>
    <div class="stexture-contact-us-content-left-local">
      如有需要，请微信扫码联系我们。
    </div>
  </div>
  <div class="stexture-contact-us-content-right">
    <div class="stexture-contact-us-content-right-img">
      <img src="../../assets/img/talk-qrcode.png" width="240px" />
    </div>
  </div>
</div>

<app-footer></app-footer>
