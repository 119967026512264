import { Component, OnInit, HostListener, Output, EventEmitter } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderComponent implements OnInit {

  @Output() goLocalAnchor = new EventEmitter<any>();

  constructor(
    private _router: Router
  ) { }


  public goRouterLink(router: string, event: any) {
    event.stopPropagation()
    event.preventDefault()
    this._router.navigate([router])
  }

  // 跳转到锚点
  public goAnchor(router: string, id: string, event: any) {
    event.stopPropagation()
    event.preventDefault()

    const routerParams: NavigationExtras = {
      queryParams: {
        id,
      }
    };
    if (!location.href.includes(router)) {
      this._router.navigate([router], routerParams)
    } else {
      this.goLocalAnchor.emit(id)
    }
  }

  public headerTitle: Array<any> = [
    // {
    //   title: '首页',
    //   router: '/home'
    // },
    {
      title: '核心能力',
      router: '/core'
    },
    {
      title: '解决方案',
      router: '/product'
    },
    {
      title: '咨询与开发',
      router: '/consultation'
    },
    {
      title: '服务支持',
      router: '/support'
    },
    {
      title: '关于智纹',
      router: '/about'
    },
  ]

  public tooltipContent: Array<any> = [
    {
      routerUrl: '/product',
      title: '黄金行业产品及方案',
      icon: '/assets/img/stexture-tooltip-gold.webp',
    },
    {
      title: '金珠自动化智能检测',
      icon: '',
      anchorId: 'stextureGold2',
      routerUrl: '/product',
    },
    {
      title: '黄金内部品质无损检测',
      icon: '',
      anchorId: 'stextureGold1',
      routerUrl: '/product',
    },
    {
      title: '黄金款式识别',
      icon: '',
      anchorId: 'stextureGold3',
      routerUrl: '/product',
    },
    {
      routerUrl: '/diamond',
      title: '钻石行业产品及方案',
      icon: '/assets/img/stexture-tooltip-diamond.webp',
    },
    {
      title: '钻石智纹码',
      icon: '',
      anchorId: 'stextureDiamond1',
      routerUrl: '/diamond',
    },
    {
      title: '钻石腰码全自动识别仪',
      icon: '',
      anchorId: 'stextureDiamond2',
      routerUrl: '/diamond',
    },
  ]

  public colorfulHeader: boolean = false;

  ngOnInit(): void {
    this.changePos();
  }

  @HostListener('window:scroll', ['$event'])
  scrolling(event) {
    this.colorfulHeader = window.pageYOffset > 10;
  }

  ngAfterViewChecked() {
    window.addEventListener('scroll', this.changePos);
  }

  private changePos() {
    let obj = document.getElementById('mdna-header');
    let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    obj.classList.add('active')
  }

}
