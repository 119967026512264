<div class="stetxture-footer-main">
  <ng-container>
    <div class="stexture-footer-img">
    </div>
  </ng-container>

  <div class="mdna-footer-list">
    <div *ngFor="let item of footerList">
      <div class="mdna-footer-list-title">
        {{ item.title }}
      </div>
      <ul>
        <li *ngFor="let subItem of item.subConternt">
          <a *ngIf="!!subItem.subUrl" target="_blank" [href]="subItem.subUrl">
            <p>{{ subItem.title }}</p>
          </a>

          <ng-container *ngIf="!subItem.subUrl">
            <a *ngIf="!!subItem.anchorId && !!subItem.routerUrl" (click)="goAnchor(subItem.routerUrl, subItem.anchorId)">
              <p>{{ subItem.title }}</p>
            </a>
            <a *ngIf="!!subItem.routerUrl && !subItem.anchorId" [routerLink]="subItem.routerUrl">
              <p>{{ subItem.title }}</p>
            </a>
            <p *ngIf="!subItem.routerUrl && !subItem.anchorId">
              {{ subItem.title }}
            </p>
          </ng-container>
        </li>
      </ul>
    </div>

    <div>
      <div>
        <img src="../../../assets/img/stexture-logo.svg" width="100px" />
      </div>
      <div class="textture-footer-tel">0755-23358866</div>
    </div>
  </div>

  <div class="stexture-footer-info">
    <div>© 深圳智纹科技有限公司</div>
    <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2022105096号</a>
    <a
      target="_blank"
      href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030502009347"
      >粤公网安备44030502009347号</a
    >
    <!-- <div class="stexture-margin-left15">|</div>
    <a>法律声明</a>
    <a>隐私政策</a> -->
  </div>
</div>
