import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { AntiFakeComponent } from './anti-fake/anti-fake.component';
import { HomeComponent } from './home/home.component';
import { TechInnovationComponent } from './tech-innovation/tech-innovation.component';
import { HeaderComponent } from './_share/header/header.component';
import { FooterComponent } from './_share/footer/footer.component';
import { HeaderAllComponent } from './_share/header-all/header-all.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { DiamondSolutionsComponent } from './diamond-solutions/diamond-solutions.component';
import { TooltipComponent } from './_share/tooltip/tooltip/tooltip.component';
import { TooltipDirective } from './_share/tooltip/tooltip.directive';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { ReactiveFormsModule } from "@angular/forms"
import { CoreCompetenceComponent } from './core-competence/core-competence.component';
import { ConsultingDevelopmentComponent } from './consulting-development/consulting-development.component';


@NgModule({
  declarations: [				
    AppComponent,
    AboutUsComponent,
    AntiFakeComponent,
    HomeComponent,
    TechInnovationComponent,
    HeaderComponent,
    FooterComponent,
    HeaderAllComponent,
    DiamondSolutionsComponent,
    TooltipComponent,
    TooltipDirective,
      ContactUsComponent,
      CoreCompetenceComponent,
      ConsultingDevelopmentComponent,
   ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    NgxEchartsModule,
    ReactiveFormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
