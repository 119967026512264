<div class="mdna-anti-fake-header">
  <app-header></app-header>

  <div [ngStyle]="{ 'padding-top': '80px' }">
    <div class="stexture-home-img">
      <div class="stexture-home-img-content">
        <div class="stexture-home-img-content1">服务支持</div>
        <div class="stexture-home-img-content2">Service Support</div>
      </div>
    </div>
  </div>
</div>

<div class="stexture-support-content" *ngFor="let item of stextureContent">
  <div class="stexture-support-content-main" [id]="item.anchorId">
    <div class="stexture-support-support-text">{{ item.title }}</div>
    <div class="stexture-home-content-img-first">
      <ul>
        <li *ngFor="let descItem of item.content">
          <img [src]="descItem.imgUrl" width="100%" />
          <div class="stexture-spport-content-text">
            <div class="stexture-spport-content-text1">{{ descItem.text }}</div>
            <div class="stexture-spport-content-text2">
              {{ descItem.detail }}
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>

<div class="stexture-support-content">
  <div class="stexture-support-content-main">
    <div class="stexture-support-support-text">服务方案</div>
    <div class="stexture-support-support-text2">
      我们致力于为客户提供长效优质服务，您可依据根据实际业务形态和阶段选择优化的服务方案
    </div>
    <div class="stexture-home-content-img-first">
      <ul>
        <li *ngFor="let subItem of descContent; let i = index">
          <div class="stexture-support-content-program">
            <div class="stexture-content-program-title">
              {{ subItem.title }}
            </div>
            <div
              *ngFor="let subContentItem of subItem.content"
              class="stexture-content-program-subItem"
            >
              <div class="stexture-content-program-subcontent">
                <a *ngIf="subContentItem.hasSupported"
                  ><img src="../../assets/img/supported.svg"
                /></a>
                <a *ngIf="!subContentItem.hasSupported"
                  ><img src="../../assets/img/disupport.svg"
                /></a>
                <p>{{ subContentItem.text }}</p>
              </div>
            </div>
            <div class="stexture-content-contact">
              <a routerLink="/contact"> 联系我们 </a>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
<app-footer (goLocalAnchor)="goLocalAnchor($event)"></app-footer>
