<div class="mdna-about-us-header">
  <app-header></app-header>

  <div [ngStyle]="{ 'padding-top': '80px' }">
    <div class="stexture-home-img">
      <div class="stexture-home-img-content">
        <div class="stexture-home-img-content1">核心能力</div>
        <div class="stexture-home-img-content2">Core Capability</div>
        <div class="stexture-home-img-content3">
          超过10年的计算机视觉、人工智能、区块链、物联网、云计算等前沿技术积累与产业转化，助力客户产业升级，创造技术价值，实现科技普惠
        </div>
      </div>
    </div>
  </div>
</div>

<div class="mdna-about-us-wrapper" *ngFor="let item of coreList">
  <div class="mdna-about-us-desc" [id]="item.anchorId">
    <div class="mdna-about-us-desc-img">
      <img [src]="item.imgUrl" />
    </div>
    <div class="mdna-about-us-desc-content">
      <div class="mdna-list-title">
        {{ item.title }}
      </div>
      <div *ngIf="!!item.title1">
        {{ item.title1 }}
      </div>
      <p [innerHTML]="item.desc"></p>
      <ng-container *ngIf="item.desc1">
        <p [ngStyle]="{ 'margin-top': '45px' }" [innerHTML]="item.desc1"></p>
      </ng-container>
    </div>
  </div>
</div>

<app-footer></app-footer>
