import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from "@angular/forms"
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.less']
})
export class ContactUsComponent implements OnInit {

  constructor(
    private titleService: Title,
  ) { }


  // 动态设置值
  dynamicSetTitle(): void {
    this.titleService.setTitle('联系我们-智纹科技');
    // this.meta.addTag({ name: 'keywords', content: '隐形水印,百货基因' });
    // this.meta.addTag({ name: 'description', content: '百货基因的优势包括完全隐形防伪和不可攻破性；百货基因的隐形水印与包装原图像的像素差小于1%，远低于人眼所能察觉的最小范围；在不改变现有包装生产工艺的前提下，可以确保嵌入百货基因隐形水印商品包装的唯一性，杜绝非法的二次复制或修改。服务热线：4000861108。' });
  }

  public userAgent: string;

  public contactForm: FormGroup;


  ngOnInit() {
    this.dynamicSetTitle();
  }

}
