<div class="mdna-tech-innovation-header">
  <app-header (goLocalAnchor)="goLocalAnchor($event)"></app-header>

  <div [ngStyle]="{ 'padding-top': '80px' }">
    <div class="stexture-home-img">
      <div class="stexture-home-img-content">
        <div class="stexture-home-img-content1">钻石行业产品及方案</div>
        <div class="stexture-home-img-content2">
          Diamond industry product and Solutions
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="mdna-tech-innovation-wrapper"
  *ngFor="let content of contentList; let i = index"
>
  <div class="mdna-tech-innovation-desc" [id]="content.anchorId">
    <div>
      <img [src]="content.imgUrl" width="450px" />
    </div>
    <div class="stexture-product-main">
      <div class="mdna-tech-innovation-desc-content">
        <div class="mdna-list-title">
          {{ content.title }}
        </div>
        <p>
          {{ content.desc }}
        </p>
      </div>

      <div class="stexture-product-content">
        <ul *ngFor="let subContent of content.descContent">
          <li>
            <img [src]="subContent.icon" />
            <p class="stexture-desc-title">{{ subContent.title }}</p>
            <p class="stexture-desc-text">{{ subContent.content }}</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<app-footer (goLocalAnchor)="goLocalAnchor($event)"></app-footer>
