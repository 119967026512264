import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router'

@Component({
  selector: 'app-diamond-solutions',
  templateUrl: './diamond-solutions.component.html',
  styleUrls: ['./diamond-solutions.component.less']
})
export class DiamondSolutionsComponent implements OnInit {

  constructor(
    private meta: Meta,
    private titleService: Title,
    private myActivatedRoute: ActivatedRoute
  ) { }

  // 动态设置值
  dynamicSetTitle(): void {
    this.titleService.setTitle('钻石行业产品及方案-智纹科技');
    // this.meta.addTag({ name: 'keywords', content: '防伪技术,百货基因' });
    // this.meta.addTag({ name: 'description', content: '百货基因防伪技术是结合心理物理学与数学形态学的新一代数学防伪技术，实现了全球领先的计算机视觉底层算法突破，是基于像素间的数学关系嵌入编码的数学防伪技术。服务热线：4000861108。' });
  }

  public contentList: Array<any> = [
    {
      anchorId: 'stextureDiamond1',
      imgUrl: '/assets/img/stexture-home-content1.webp',
      title: '钻石智纹码',
      desc: '通过精密仪器及强大的AI算法，从天然钻石中无损提取唯一的「钻石智纹码」，记录钻石的属性、来源、流通过程等全维度信息。',
      descContent: [
        {
          title: '唯一',
          icon: '/assets/img/stexture-diamond-desc1.svg',
          content: '每一颗天然钻石均生来不同，做天然钻石的数字身份证'
        },
        {
          title: '无损',
          icon: '/assets/img/stexture-product-desc2.svg',
          content: '专利自动化精密仪器，快速、高效、无损生成钻石智纹码'
        },
        {
          title: '可靠',
          icon: '/assets/img/stexture-diamond-desc2.svg',
          content: '超10万颗GIA证书钻石的采集及应用验证'
        }
      ]
    },
    {
      anchorId: 'stextureDiamond2',
      imgUrl: '/assets/img/stexture-home-content2.webp',
      title: '钻石腰码全自动识别仪',
      desc: '通过精密机械结构与计算机视觉算法相结合，自动识别钻石腰码，真正助力钻石业务的降本提效',
      descContent: [
        {
          title: '便捷',
          icon: '/assets/img/stexture-diamond-desc3.svg',
          content: '体积小，便于携带，应用方便，连接手机即可使用'
        },
        {
          title: '精密',
          icon: '/assets/img/stexture-diamond-desc4.svg',
          content: '精密机械传动结构与计算机视觉算法结合，腰码之小不再小'
        },
        {
          title: '高效',
          icon: '/assets/img/stexture-product-desc4.svg',
          content: '读取钻石腰码不再费事费力，助力实现钻石业务的降本提效'
        }
      ]
    },
  ]

  public goLocalAnchor(id: string) {
    let anchor = document.getElementById(id);
    anchor.scrollIntoView()
  }

  ngOnInit(): void {
    this.dynamicSetTitle();
  }

  ngAfterViewInit(): void {
    this.myActivatedRoute.queryParams.subscribe(params => {
      if (params.id) {
        let anchor = document.getElementById(params.id)
        anchor.scrollIntoView()
      }
    })

  }

}
