<div class="stexture-contact-us-header">
  <app-header></app-header>
  <div [ngStyle]="{ 'padding-top': '80px' }">
    <div class="stexture-home-img">
      <div class="stexture-home-img-content">
        <div class="stexture-home-img-content1">联系我们</div>
        <div class="stexture-home-img-content2">Contact Us</div>
      </div>
    </div>
  </div>
</div>

<div class="stexture-contact-us-content">
  <div class="stexture-contact-us-content-left">
    <p>深圳智纹科技有限公司</p>
    <div>国际专线：+86-0755-2335886</div>
    <div>客服邮箱：<a href="mailto:hi@stexture.com">hi@stexture.com</a></div>
    <div class="stexture-contact-us-content-left-local">
      <span [ngStyle]="{ width: '100px' }">公司地址：</span>
      <span>深圳市南山区粤海街道科技园社区科苑路15号科兴科学园A栋A1-1201</span>
    </div>
  </div>
  <div class="stexture-contact-us-content-right">
    <p>表单联系</p>
    <div class="stexture-contact-us-content-right-div">
      如需了解产品或销售咨询，可扫码填写表单或来通过电话与我们联系。
    </div>
    <div class="stexture-contact-us-content-right-img">
      <img src="../../assets/img/talk-qrcode.png" width="240px" />
      <div class="stexture-contact-us-content-right-text">
        微信或企业微信扫码
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
